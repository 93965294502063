import React from 'react'
import { Link } from 'gatsby'
import { useStyletron } from 'baseui'
import { Table } from 'baseui/table'
import { H2, Paragraph3, Paragraph2 } from 'baseui/typography'
import { FcCheckmark } from 'react-icons/fc'
import { BsDash } from 'react-icons/bs'
import Emoji from './Emoji'
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from 'baseui/popover'
import { isMobile } from 'react-device-detect'

const Pricing = () => {
  const [css] = useStyletron()

  const pricingContainer = css({
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    marginTop: '2rem',
    marginBottom: '1rem',
  })

  const pricingTitle = css({
    marginBottom: '2rem',
    textAlign: 'center',
  })

  const tableHeader = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
  })

  return (
    <div className={pricingContainer}>
      <div className={pricingTitle} id="pricing">
        <H2>Compare nossos serviços:</H2>
        {/* <Paragraph3>
          We want your business to grow with us. Start for free, then be a Pro
        </Paragraph3> */}
      </div>
      <div className="pricingTable">
        <Table
          columns={[
            <div className={tableHeader}>
              Características incluídas
              <small>
                {isMobile
                  ? '(toque para mais informações)'
                  : '(passe o mouse em cima para mais informações)'}
              </small>
            </div>,
            <PlanHeader
              title="Walink Gratuito "
              description="USD $0 para sempre"
              to="/#create-link"
              // emoji="😁"
            />,
            <PlanHeader
              title="Walink Premium"
              description="USD $6/ano por link"
              emoji="👑"
              to="/premium"
            />,
          ]}
          data={[
            [
              <PlanItem content="Aumente a conversão de seus cliques no chat com o domínio wa.link. Os usuários sabem que ele abrirá um chat do WhatsApp, tornando-o mais eficaz do que outras alternativas.">
                Clique para conversar com o domínio wa.link
              </PlanItem>,
              <Check />,
              <Check />,
            ],
            [
              <PlanItem content="Adicione uma mensagem personalizada que os usuários podem enviar a você para iniciar a conversa, sem codificação complexa ou afetar o comprimento do link.">
                Mensagem personalizada
              </PlanItem>,
              <Check />,
              <Check />,
            ],
            [
              <PlanItem content="Código QR fácil de digitalizar que abrirá um chat WhatsApp, assim como o link.Link da marca (wa.link/SuaMarca)">
                Código QR
              </PlanItem>,
              <Check />,
              <Check />,
            ],
            [
              <PlanItem content="Links com marcas são uma forma profissional de compartilhar seu WhatsApp em qualquer canal digital e tradicional, como TV, rádio, imagens ou vídeos. Os usuários podem acessar seu chat apenas digitando seu link de marca em qualquer navegador da web. Não há necessidade de lembrar números de telefone.">
                Link da marca (wa.link/SuaMarca)
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="Altere as informações do seu link a qualquer momento. Atualize a mensagem personalizada e número de telefone sem se preocupar em perder clientes, ou modifique a URL, se necessário.">
                Atualizar informações do link (telefone, url e mensagem
                personalizada)
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="Acompanhe a quantidade de cliques em seus links por dia, hora e mês. Além disso, obtenha a fonte, o sistema operacional e o país de seus clientes.">
                Análise de cliques
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="Torne seu WhatsApp mais fácil de encontrar listando seu negócio em nosso mecanismo de busca (open.wa.link/search), usado por milhares de pessoas todos os dias para abrir chats WhatsApp sem salvar contatos.">
                Apareça nos resultados da busca
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="Crie links para seu catálogo WhatsApp Business com uma URL fácil de lembrar. Os usuários podem clicar no link e seus produtos aparecerão instantaneamente para compra.">
                Links de catálogo WhatsApp
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="Mais de uma linha WhatsApp no seu negócio? Reúna todos os seus links dentro de uma página Multilink para oferecer opções para que seus clientes cheguem até você em um único ponto de contato: sua página Multilink!">
                Página Multilink*
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="Nossa equipe de suporte está disponível para ajudar e resolver suas perguntas por e-mail">
                Suporte por e-mail
              </PlanItem>,
              <Not />,
              <Check />,
            ],
            [
              <PlanItem content="Multiagente (várias linhas WhatsApp, um único wa.link): Aumente a quantidade de chats que você pode administrar distribuindo automaticamente os chats recebidos por vários números de telefone WhatsApp, para que você possa ter várias pessoas por trás de um único link wa.link!">
                Multiagente (várias linhas WhatsApp, um único link)
              </PlanItem>,
              <Not />,
              <div style={{ textAlign: 'center', width: '100%' }}>
                <small>USD $5/agente/mês</small>
                <div>
                  <small>(14 dias de teste gratuito)</small>
                </div>
              </div>,
            ],
          ]}
        />
      </div>
      <div style={{ paddingLeft: '15px' }}>
        <Paragraph3>
          <small>
            * A página Multilink está disponível para clientes com 2 ou mais
            links em seu Plano Premium
          </small>
        </Paragraph3>
      </div>
    </div>
  )
}

function Check() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <FcCheckmark size="18px" />
    </div>
  )
}

function Not() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <BsDash size="18px" style={{ color: 'darkgrey' }} />
    </div>
  )
}

function PlanHeader({ title, description, emoji, to }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'center',
      }}
    >
      <div>
        <strong>
          {emoji && <Emoji symbol={emoji} />} <Link to={to}>{title}</Link>
        </strong>
      </div>
      <div>
        <small>{description}</small>
      </div>
    </div>
  )
}

function PlanItem({ children, content }) {
  return (
    <StatefulPopover
      showArrow
      content={
        <>
          <Paragraph2 padding="scale300">
            <strong>{children}:</strong>
            <br />
            {content}
          </Paragraph2>
        </>
      }
      accessibilityType={'tooltip'}
      triggerType={TRIGGER_TYPE.hover}
      placement={PLACEMENT.left}
      overrides={{
        Body: {
          style: {
            width: '280px',
            marginRight: '-200px',
            position: 'absolute',
            textAlign: 'center',
            borderTopLeftRadius: '15px',
            borderBottomLeftRadius: '15px',
            borderTopRightRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
        Inner: {
          style: {
            borderTopLeftRadius: '15px',
            borderBottomLeftRadius: '15px',
            borderTopRightRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
      }}
    >
      {children}
    </StatefulPopover>
  )
}

export default Pricing
