import React from 'react'
import { Link, navigate } from 'gatsby'
import { Button, SHAPE, SIZE, KIND } from 'baseui/button'
import { StatefulMenu } from 'baseui/menu'
import { StatefulPopover } from 'baseui/popover'
import { MdTranslate } from 'react-icons/md'
import scrollTo from 'gatsby-plugin-smoothscroll'
import walinkLogo from '../images/logo-walink.svg'
import Emoji from './Emoji'

const Header = ({ page }) => {
  const goToLang = item => {
    if (item.href) {
      window.location.href = item.href
    }
  }

  const goToPremium = item => {
    console.log(page)
    if (item.href && item.href === 'premium') {
      navigate('/premium')
    }
    if (item.href && item.href === 'register') {
      window.location.href = 'https://app.wa.link/register'
    }

    if (item.href && item.href === 'login') {
      window.location.href = 'https://app.wa.link/'
    }

    if (item.href && item.href === 'pricing') {
      scrollTo('#pricing')
      // if (page === 'premium') {
      //   navigate('/#pricing')
      // } else {
      //   scrollTo('#pricing')
      // }
    }
  }

  return (
    <header
      style={{
        background: '#fff',
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0.5rem 1.0875rem`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Link to="/">
          <img
            src={walinkLogo}
            alt="white-logo"
            style={{ height: 45, cursor: 'pointer', marginBottom: -5 }}
          />
        </Link>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <a
            style={{
              fontSize: '.8rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '15px',
            }}
            href="https://walink.io/blog"
          >
            Blog
          </a>

          <StatefulPopover
            content={() => (
              <StatefulMenu
                onItemSelect={({ item }) => goToLang(item)}
                items={[
                  { label: 'English', href: 'https://create.wa.link' },
                  { label: 'Español', href: 'https://crear.wa.link' },
                ]}
              />
            )}
            showArrow
            renderAll
            returnFocus
            autoFocus
          >
            <Button
              kind={KIND.minimal}
              size={SIZE.compact}
              shape={SHAPE.pill}
              overrides={{
                BaseButton: {
                  style: {
                    marginRight: '2px',
                    marginLeft: '2px',
                  },
                },
              }}
            >
              <MdTranslate size="16px" style={{ marginRight: 4 }} />
              lang
            </Button>
          </StatefulPopover>

          <StatefulPopover
            overrides={{
              Body: {
                style: {
                  borderTopLeftRadius: '15px',
                  borderBottomLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                  borderBottomRightRadius: '15px',
                },
              },
              Inner: {
                style: {
                  borderTopLeftRadius: '15px',
                  borderBottomLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                  borderBottomRightRadius: '15px',
                },
              },
            }}
            content={() => (
              <StatefulMenu
                overrides={{
                  List: {
                    style: {
                      borderTopLeftRadius: '15px',
                      borderBottomLeftRadius: '15px',
                      borderTopRightRadius: '15px',
                      borderBottomRightRadius: '15px',
                    },
                  },
                }}
                onItemSelect={({ item }) => goToPremium(item)}
                items={[
                  { label: 'O que é Premium?', href: 'premium' },
                  { label: 'Preço', href: 'pricing' },
                  { label: 'Registro', href: 'register' },
                  { label: 'Conecte-se', href: 'login' },
                ]}
              />
            )}
            showArrow
            renderAll
            returnFocus
            autoFocus
          >
            <Button
              shape={SHAPE.pill}
              size={SIZE.compact}
              kind={KIND.primary}
              overrides={{
                BaseButton: {
                  style: {
                    marginRight: '3px',
                    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                  },
                },
              }}
            >
              <Emoji symbol={'👑'} size="12px" /> Premium
            </Button>
          </StatefulPopover>
        </div>
      </div>
    </header>
  )
}
export default Header
